import React, { Component } from 'react';
import { Link } from "react-router-dom";
import CatLoading from './catloading';

class MenuCategories extends Component {

    data = this.props.Data;
    language = this.props.Language;

    render() {
        return (
                    <div className={`head-categories-item ${this.props.IsActive ? 'head-categories-item-active':''}`} onClick={ () => this.props.handleCategory(this.data.id)} >
                        <div className='head-categories-icon'>
                            <CatLoading Img={this.data.logo} />
                        </div>
                        <span className={`head-categories-name`}>
                            {
                                this.props.Language == "en" ? this.data.name_eng
                                : this.props.Language == "krd" ? this.data.name_krd
                                : this.props.Language == "tr" ? this.data.name_tr
                                : this.data.name_ar
                            }
                        </span>
                    </div>
                );
    }
}

export default MenuCategories;