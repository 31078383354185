import React, { Component } from 'react';
import axios from 'axios';
import Item from './item';
import { Link, Navigate } from "react-router-dom";
import Loader from './../general/loader';
import backArrow from '../../assets/backArrow.png'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css'
import Lang from '../general/Lang';
import Menu from './Menu';
import { VscFeedback } from "react-icons/vsc";


class Category extends Component {

    settings = this.props.Settings;
    language = this.props.Language;

    state = {
        menus: [],
        selectedMenu: 0,
        load: false,
        back: false,
        logo: true,
    }

    async componentDidMount() {

        const {data} = await axios.get("https://manage.caviarmenu.com/api/groups/swissland");
        this.setState({ menus: data }, () => {
            this.setState({load: true, selectedMenu: this.props.Menu});
        });
          
        // setTimeout(() => {
        //     this.setState({ logo: true });
        // }, 2000);

    }

    handleChange = e => {
        this.props.handleLangaugeSubmit(e);
    }

    handleMenuClick = e => {
        this.setState({load: false});
        this.setState({selectedMenu: e}, () => {
            this.setState({load: true});
        });
    }


    // back = event => {
    //     event.preventDefault();
    //     this.setState({ back : true });
    // }

    render() {
        return (

            <>
                { this.state.back && <Navigate to="/" replace={true} /> }
                <div className='category'>
                    <div className="head">

                            <div className='send-feedback'>
                                <Link to={"/feedback"}>
                                <VscFeedback /> <span>Feedback</span>
                                </Link>
                            </div>

                            <Link to={"/"}>
                                <img src={backArrow} className="backward" alt="" />
                            </Link>

                            <div className="language">
                                <Lang handleLangaugeSubmit = {this.props.handleLangaugeSubmit} ></Lang>
                            </div>
                            
                        {
                            this.state.logo? 
                            <img src={this.settings.logo} alt="" />
                            :
                            <SkeletonTheme   baseColor="#FFFFFF12" highlightColor="#8a4628">
                                <Skeleton height={150} width={150} circle={true} duration={1}  />
                            </SkeletonTheme>
                        }
                    </div>

                    {/* <div className="info">
                        <button>About Us</button>
                        <h3>{this.settings.name}</h3>
                    </div> */}

                    <div className="menus">
                        {
                            this.state.load 
                                ?
                                    this.state.menus.map( (menu, index) => <Menu 
                                                                                    key={index} 
                                                                                    Data = {menu}
                                                                                    Index={index}
                                                                                    Language = {this.props.Language} 
                                                                                    selectedMenu = { this.state.selectedMenu == index} 
                                                                                    handleMenuClick = { this.handleMenuClick } ></Menu>)
                                : 
                                <Loader></Loader>
                        }
                    </div>

                    <div>
                        {
                            this.state.load ?
                            (
                                this.state.menus[this.state.selectedMenu].groups?.length != 0 ?
                                Object.entries(this.state.menus[this.state.selectedMenu].groups).map(([groupsKey, group]) => {

                                return (
                                    <div key={groupsKey}>
                                    <h2 className={`category-list-head`} style={{'animation': `tracking-in-expand 0.5s cubic-bezier(0.215, 0.610, 0.355, 1.000) both`}}> 
                                        {
                                            this.props.Language == "en" ? group.name_eng
                                            : this.props.Language == "krd" ? group.name_krd
                                            : group.name_ar
                                        }
                                    </h2>

                                    <div className={`category-list`} style={ {'direction': this.props.Language != "en"? "rtl":"ltr"} } >
                                        {
                                            group.categories.map((category, index) => (
                                            <Item 
                                                key={index} 
                                                Data={category}
                                                MenuId={this.state.menus[this.state.selectedMenu].id}
                                                Language={this.props.Language} 
                                                Index={index} 
                                                Length={this.state.menus.length}
                                            />
                                            ))
                                        }
                                    </div>
                                    </div>
                                );

                                })
                                : <div className='commingsoon'>
                                    {
                                        this.props.Language == "en" ? "Comming Soon"
                                        : this.props.Language == "krd" ? "ل نێزیک"
                                        : "قريبا"
                                    }
                                    </div>
                            ) : 
                            <Loader />
                        }
                    </div>



                    <div className="footer">
                        Powered By <a href='https://www.instagram.com/caviar.menu/' className='caviar'>Caviar Menu</a>
                    </div>

                </div>
            </>
        );
    }
}

export default Category;