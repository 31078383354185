import React, { Component } from 'react';
import Imgloading from './itemloading';
import { PhotoView } from 'react-photo-view';

class Two extends Component {

    data = this.props.Data;
    language = this.props.Language;


    addItemToCart = () => {
        this.props.addCartItem(this.data)
    }

    render() {
        return ( 
            <PhotoView src={this.data.image} overlay={this.data} >
                <div className="item"> 
                    <Imgloading Data={this.data} />
                    {/* <span className="item-order">{this.data.order??0}</span> */}
                    <div className='style-two-info'>
                        
                        <div className='style-two-info-footer'>
                            <div>
                                <h5 style={this.props.Language == "en"? {letterSpacing:'1px'}: {}}>
                                    {
                                        this.props.Language == "en" ? this.data.name_eng
                                        : this.props.Language == "krd" ? this.data.name_krd
                                        : this.props.Language == "tr" ? this.data.name_tr
                                        : this.data.name_ar
                                    }          
                                </h5>
                                {/* <p> 
                                    {
                                        this.props.Language == "en" ? this.data.desc_eng
                                        : this.props.Language == "krd" ? this.data.desc_krd
                                        : this.props.Language == "tr" ? this.data.desc_tr
                                        : this.data.desc_ar
                                    } 
                                </p> */}
                            </div>
                            <div className='price'>
                                <span> 
                                    {this.data.price} {(this.props.Language == "en" ||  this.props.Language == "tr")? 'IQD': 'د.ع.'} 

                                    {
                                        this.data.prices.length > 1? <b> ( 
                                            {
                                                this.props.Language == "en" ? 'Multi Prices'
                                                : this.props.Language == "krd" ? 'چەندین بها'
                                                : this.props.Language == "tr" ? 'Çoklu Fiyatlar'
                                                : 'أسعار متعددة'
                                            }  ) </b> : ''
                                    }
                                </span>
                                
                            </div>
                        </div>
                    </div>
                </div>
            </PhotoView>
        );
    }
}

export default Two;