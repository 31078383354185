import React, { Component } from 'react';
import imgLoading from '../../../assets/itemLoading.gif';

class Imgloading extends Component {

    state = { 
        loading: true,
        onError: false,
     }

    data = this.props.Data;

    handleLoad = e => {
        this.setState({loading: false});
    }

    handleError = e => {
        this.setState({loading: false});
        this.setState({onError: true});
    }
    
    render() {
        return (
            <div className='image-loading'>
                { this.state.loading && <img src={imgLoading}></img> }
                    <img 
                        style={this.state.onError ? {display: 'none'} : {}}
                        src={this.data.image} 
                        onLoad={this.handleLoad} 
                        onError={this.handleError}
                        ></img>
                {this.state.onError && <img src={imgLoading}></img>}
            </div>
        );
    }
}

export default Imgloading;